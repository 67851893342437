<template>
  <div>
    <!-- <app-bar ref="refAppBar"></app-bar> -->
    <v-card elevation="0">
      <v-card-title>
        <!-- 設定済コンテンツ一覧 -->
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（キーワード検索）"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :items-per-page="-1"
        :mobile-breakpoint="0"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <template v-slot:body="{ items: desserts }">
          <tbody v-if="desserts.length > 0">
            <tr v-for="row in desserts" :key="row.index">
              <td class="text-center">
                <v-icon
                  class="pa-1"
                  @click="
                    editItem(
                      row.id,
                      row.shop_site_id,
                      row.site_content_id,
                      row.site_id
                    )
                  "
                >
                  mdi-pencil
                </v-icon>
              </td>
              <td class="text-center">
                <div class="auto_on" v-if="row.is_auto">更新中</div>
                <div class="auto_off" v-else>--</div>
              </td>
              <td class="text-center">
                <div v-if="row.is_auto">
                  <span class="next_time">{{
                    utilDate.getTimeFormat(row.next_update)
                  }}</span
                  ><br />
                  <span class="mini next_date">{{
                    utilDate.getDateFormatShortDisplay(row.next_update)
                  }}</span>
                </div>
                <div class="auto_off" v-else>--</div>
              </td>
              <td class="text-start">
                <span class="text-subtitle-1">{{ row.site_content_name }}</span>
              </td>
              <td class="text-start">
                <span
                  :style="row.color.length != 0 ? 'color:' + row.color : ''"
                  >{{ row.site_name }}</span
                >
              </td>
              <td class="text-start">
                <v-icon dense> {{ row.content_icon }} </v-icon
                ><span class="text-align-middle mini"
                  >&nbsp;{{ row.content_name }}</span
                >
              </td>
              <td class="text-start">
                <span class="mini">{{
                  utilDate.getDatetimeFormatLongDisplay(row.last_update)
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td :colspan="headers.length" style="text-align: center">
                表示するデータがありません
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { useRouter } from "@/utils";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import appBar from "./appBar.vue";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  components: {
    appBar,
  },
  setup(_, ctx) {
    const { router } = useRouter();
    const updateContentsRepository = repositoryFactory.get("updateContents");
    const refAppBar = ref();
    const state = reactive({
      search: "",
      headers: [
        {
          text: "編集",
          value: "cog",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "自動更新",
          value: "is_auto",
          sortable: true,
          align: "center",
          width: 80,
        },
        {
          text: "次回更新",
          value: "next_update",
          sortable: true,
          align: "center",
          width: 80,
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: true,
          class: "td_site_content_name",
        },
        {
          text: "媒体",
          value: "site_name",
          sortable: true,
          class: "td_site_name",
        },
        {
          text: "タイプ",
          value: "content_name",
          sortable: true,
          class: "td_content_name",
        },
        {
          text: "最終更新日",
          value: "last_update",
          sortable: true,
          width: 120,
        },
      ],
      desserts: [], // DataTablesレコード格納用
    });

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      state.desserts.splice(0);
      await updateContentsRepository
        .list(store.getters["shops/currentShop"].id)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const site_name =
                response.data[key].site_name +
                (response.data[key].memo
                  ? "[" + response.data[key].memo + "]"
                  : "");
              state.desserts.push({
                "id": response.data[key].id ?? 0,
                "cog": "",
                "is_auto": response.data[key].is_auto,
                "shop_site_id": response.data[key].shop_site_id,
                "site_id": response.data[key].site_id,
                "site_name": site_name,
                "site_content_name": response.data[key].site_content_name,
                "is_random": response.data[key].is_random,
                "memo": response.data[key].memo,
                "color": response.data[key].color,
                "site_content_id": response.data[key].site_content_id,
                "content_name": response.data[key].content_name,
                "content_icon": response.data[key].content_icon,
                "content_color": response.data[key].content_color,
                "start_update": utilDate.convertSplitStrToDate(
                  response.data[key].start_update
                ),
                "last_update": utilDate.convertSplitStrToDate(
                  response.data[key].last_update
                ),
                "next_update": utilDate.convertSplitStrToDate(
                  response.data[key].next_update
                ),
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ShopSiteContent.vue/init ShopSiteContentRepository.list_shop (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    init();

    // 店舗媒体コンテンツの設定変更
    const editItem = async (
      update_content_id,
      shop_site_id,
      site_content_id,
      site_id
    ) => {
      await router.push({
        name: "synchro-contents-edit",
        params: {
          update_content_id: update_content_id,
          shop_site_id: shop_site_id,
          site_content_id: site_content_id,
          site_id: site_id,
        },
      });
    };

    // 返却オブジェクト定義
    return {
      setting,
      utilDate,
      refAppBar,
      ...toRefs(state),
      init,
      editItem,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_site_content_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_site_name {
  min-width: 200px;
}
::v-deep .td_content_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .auto_on {
  display: inline-block;
  padding: 4px;
  background-color: red;
  border-radius: 3px;
  font-size: 0.75em;
  font-weight: bold;
  color: white;
  animation: blinking 1s ease-in-out infinite alternate;
}
@keyframes blinking {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

::v-deep .auto_off {
  font-size: 0.75em;
  color: gray;
}
::v-deep .next_date {
  color: gray;
}

::v-deep .col_hide {
  display: none;
}
::v-deep .mini label {
  font-size: 0.75em;
}
.shop_site_span {
  font-size: 0.75em;
  word-break: break-all;
}
</style>
